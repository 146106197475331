import SessionService from "./sessionService";

export default class UserActivityService {
    static lastActiveOn = "";
    static checkIntervalTime = 60 * 1000; // Interval in ms after which app checks for user inactivity
    static expiryTimeoutInMs = 1 * 60 * 60 * 1000; // (1hr) Time till which user can stay inactive
    static sessionExpiryWarningTime = 1000 * 30; // Time till which user can decide if they want to continue or logout
    static sessionExpiryWarningTimeInSecs = 30;
    static oktaAuthClient = null;
    static context = null;
    static compRef = null;

    static setLastActiveTime = () => {
        UserActivityService.lastActiveOn = new Date();
    }

    static trackUserActivity = (compRef, oktaAuthClient) => {
        UserActivityService.compRef = compRef;
        UserActivityService.oktaAuthClient = oktaAuthClient;
        UserActivityService.startTracking();
    }

    static startTracking = () => {
        UserActivityService.setSessionWarningTimer();
        //UserActivityService.oktaAuthClient.session.refresh();// refresh the Okta session
        document.addEventListener('keypress', (event) => {
            console.log("keypress event", event);
            UserActivityService.setLastActiveTime();
        });

        document.addEventListener('mousemove', (event) => {
            UserActivityService.setLastActiveTime();
        });
    }

    static untrackUserActivity = (_context) => {
        UserActivityService.context = _context;
        UserActivityService.stopTracking();
    }

    static stopTracking = () => {
        document.removeEventListener('keypress');
        document.removeEventListener('mousemove');
        clearInterval(UserActivityService.sessionWarningTimerRef);
        clearInterval(UserActivityService.sessionLogoutTimerRef);
        UserActivityService.lastActiveOn = null;
    }

    static sessionWarningTimerRef = null;
    static sessionLogoutTimerRef = null;

    static setSessionWarningTimer = () => {
        UserActivityService.sessionWarningTimerRef = setInterval(() => {
            if (UserActivityService.lastActiveOn) {
                console.log("Diff time : ", (new Date() - UserActivityService.lastActiveOn));
                let timeDiff = (new Date() - UserActivityService.lastActiveOn);
                if (timeDiff > (UserActivityService.expiryTimeoutInMs - UserActivityService.sessionExpiryWarningTime)) {
                    UserActivityService.compRef.setState({
                        showSessionTimeoutDialog: true
                    });
                    UserActivityService.setSessionLogoutTimer();
                } else {
                    console.log("user not logged out", timeDiff);
                }
            }
        }, UserActivityService.checkIntervalTime);
    }

    static setSessionLogoutTimer = () => {
        UserActivityService.sessionLogoutTimerRef = setInterval(() => {
            UserActivityService.logout();
        }, UserActivityService.sessionExpiryWarningTime);
    }

    static logout = async () => {
        console.log("Access Token Revoked.!");
        await UserActivityService.oktaAuthClient.revokeAccessToken()
        await UserActivityService.oktaAuthClient.signOut();
        await UserActivityService.oktaAuthClient.tokenManager.clear();
        localStorage.removeItem(SessionService.storageKey);
        UserActivityService.context.setViaLogin({ username: "elmo" });
    }
}