import config from '../../config';
import OktaSignIn from '@okta/okta-signin-widget';
import ApplicationService from '../../shared/services/applicationService';
import porzio_gst_icon from "../../public/images/porzio_global_spend_transparency_logo_small.png";
import ApiService from '../../shared/services/apiService';

export default class OktaAuthService {

    static authWidget = {};
    static tokensCache = null;
    static authClient = null;

    static getAccessToken = () => {
        // check expiry if expired, renew()-> replace old with new -> and pass the new Token
        return OktaAuthService.tokensCache?.accessToken?.accessToken;
    }

    static INIT = () => {
        const { issuer, clientId, redirectUri, scopes } = config.oidc;

        OktaAuthService.authWidget = new OktaSignIn({
            baseUrl: issuer.split('/oauth2')[0],
            clientId: clientId,
            redirectUri: redirectUri,
            logo: porzio_gst_icon,
            authParams: { issuer, scopes },
            features: { idpDiscovery: true },
            idpDiscovery: { requestContext: ApplicationService.OktaRequestContext },
            i18n: { en: { 'primaryauth.title': `Sign in to ${ApplicationService.applicationName} - ${ApplicationService.appGstVersionString}`, }, }
        });
        // cache
        OktaAuthService.authClient = OktaAuthService.authWidget.authClient;

        /** subscription required by okta for not throwing warnings in the console */
        OktaAuthService.authClient.authStateManager.subscribe((resultObj) => {
            // console.log("auth state changed", resultObj);
        });
    }


    static checkSingleSignOn = (onSuccessCallback, onErrorCallback) => {
        OktaAuthService.authClient.token.getWithoutPrompt({
            responseType: ['id_token', 'token'], // or array of types
        })
            .then((successObj) => OktaAuthService.handleSuccess(successObj, onSuccessCallback))
            .catch((errorObj) => OktaAuthService.handleError(errorObj, onErrorCallback))
    }

    static showSignInWidget = (_renderEl, onSuccessCallback, onErrorCallback) => {
        OktaAuthService.authWidget.renderEl({ el: _renderEl },
            (successObj) => OktaAuthService.handleSuccess(successObj, onSuccessCallback),
            (errorObj) => OktaAuthService.handleError(errorObj, onErrorCallback)
        );
    }

    //---
    static handleError = (errorObj, onErrorCallback) => {

        console.log(errorObj);

        // clear the cache and stop the monitor
        OktaAuthService.cancelSession();

        // call the errorCallback
        if (onErrorCallback) {
            onErrorCallback(errorObj);
        }
    }

    static handleSuccess = (successObj, onSuccessCallback) => {

        // cache the access-token for api calls
        OktaAuthService.tokensCache = successObj?.tokens;

        // set the token in the okta-token manager (application-storage)
        OktaAuthService.authClient.tokenManager.setTokens(successObj.tokens);

        // auto-renew-session of expired
        OktaAuthService.startTokenValidityMonitor();

        // call the callback
        if (onSuccessCallback) {
            onSuccessCallback(successObj);
        }
    }

    // +++++++-refresh-session-logic-++++++++++++++++++++++++
    static tokenMonitorIntervalRef = null;

    static cancelSession = () => {
        OktaAuthService.tokensCache = null;
        OktaAuthService.stopTokenValidityMonitor();
    }

    static stopTokenValidityMonitor = () => {
        if (OktaAuthService.tokenMonitorIntervalRef) {
            clearInterval(OktaAuthService.tokenMonitorIntervalRef);
        }
    }

    static startTokenValidityMonitor = () => {
        OktaAuthService.tokenMonitorIntervalRef = setInterval(() => {
            console.log("Token Renew Requested.!")
            OktaAuthService.renewToken();
        }, 15 * 60 * 1000);
    }

    static renewToken = () => {
        OktaAuthService.authClient.tokenManager.renew('accessToken')
            .then((accessTokenObj) => {
                // ---
                var updatedTokens = {
                    ...OktaAuthService.tokensCache,
                    accessToken: accessTokenObj
                };

                //  cache
                OktaAuthService.tokensCache = updatedTokens;
                OktaAuthService.authClient.tokenManager.setTokens(updatedTokens);
                console.log('Token renewed before expiry:', updatedTokens);
            })
            .catch(err => {
                console.error('Error renewing token before expiry:', err);
            })
    }

    // - JWT Decode
    //      -> isTokenExpired(accessToken:) => bool {}
    //      -> compare with the cu


    // static renewTokenIfCloseToExpiry = () => {
    //     const accessToken = OktaAuthService.tokensCache.accessToken;
    //     const expiryTime = accessToken.expiresAt * 1000; // Convert to milliseconds
    //     const currentTime = Date.now();
    //     const remainingTime = expiryTime - currentTime;
    //     const bufferTime = 10 * 60 * 1000; // 10 minutes before expiry

    //     if (remainingTime <= bufferTime) // near expiry time
    //     {
    //         // set the token to ensure renew
    //         OktaAuthService.authClient.tokenManager.setTokens(OktaAuthService.tokensCache);

    //         setTimeout(() => {

    //             OktaAuthService.authClient.tokenManager.renew('accessToken')
    //                 .then((accessTokenObj) => {
    //                     var updated = {
    //                         ...OktaAuthService.tokensCache,
    //                         accessToken: accessTokenObj
    //                     };

    //                     //  cache
    //                     OktaAuthService.tokensCache = updated;
    //                     OktaAuthService.authClient.tokenManager.setTokens(updated);
    //                     console.log('Token renewed before expiry:', updated);
    //                 })
    //                 .catch(err => {
    //                     console.error('Error renewing token before expiry:', err);
    //                 })
    //         }, 250);
    //     }
    // };




    //---
}